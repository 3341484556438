import { createSlice } from "@reduxjs/toolkit";
import { globalStateInterface } from "../interfaces/interfaces";

const initialState: globalStateInterface = {
  counter: 0,
  createSessionData: null,
  addedGroupPlayers: [],
  joinData: "",
};

const globalSlice = createSlice({
  name: "globalSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => {
      state.counter += 1;
    },
    decrement: (state) => {
      state.counter -= 1;
    },

    setCreateSessionData: (state, { payload }) => {
      state.createSessionData = payload;
    },
    setAddedGroupPlayers: (state, { payload }) => {
      state.addedGroupPlayers = [...state.addedGroupPlayers, payload];
    },
    removedGroupPlayers: (state, { payload }) => {
      const players = state.addedGroupPlayers?.filter(
        (player) => player?._id !== payload?._id
      );
      state.addedGroupPlayers = [...players];
    },
    setJoinData: (state, { payload }) => {
      state.joinData = payload;
    },
  },
  extraReducers: {},
});

export const {
  increment,
  decrement,
  setCreateSessionData,
  setAddedGroupPlayers,
  removedGroupPlayers,
  setJoinData,
} = globalSlice.actions;

export default globalSlice.reducer;
